import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';

import React from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { useScrollDispatch } from '../../../../contexts';

export const ScrollToDateButton: React.FC<{}> = () => {
    const scrollTo = useScrollDispatch();
    return (
        <Button
            data-testid="scroll-to-date-button"
            style="fill"
            onClick={() => {
                scrollTo({ action: 'scroll', target: 'date-selector' });
            }}
            data-heap-id={LISTING_HEAP_IDS.TIMED_ENTRY_ANCHOR_BUTTON_CLICK}
            size="block"
        >
            {gettext('Select tickets')}
        </Button>
    );
};
